import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  confirmVerificationCode,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import {
  signInStart,
  signInFailure,
  signInSuccess,
} from "../redux/user/userSlice";

function Signup() {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [handleSubmited, setHandleSubmited] = useState(false);
  const [formData, setFormData] = useState({});
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [verificationCode, setVerificationCode] = useState(""); // 인증번호 입력 값
  const [smsSendSuccess, setSmsSendSuccess] = useState(""); // 인증번호 전송 성공 여부
  const [smsConfirmed, setSmsConfirmed] = useState(""); // 인증번호 확인 성공 여부
  const dispatch = useDispatch();

  auth.languageCode = "ko";

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA가 해결되었을 때 콜백
      },
    });
  };

  const handleSend = () => {
    setSmsSendSuccess("success");
    generateRecaptcha(); // reCAPTCHA 생성

    if (!formData.phonenumber) {
      return;
    }

    const phone = `+82${formData.phonenumber.slice(1)}`; // 국제 전화번호 형식으로 변환
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS가 전송된 후 처리
        window.confirmationResult = confirmationResult;
        setConfirmationResult(confirmationResult);
        console.log(confirmationResult);
        console.log("인증번호가 전송되었습니다.");
      })
      .catch((error) => {
        // SMS 전송 실패 시 처리
        console.log("인증번호 전송에 실패했습니다. 다시 시도해 주세요.");
        setSmsSendSuccess("failure");
      });
  };

  const handleVerifyCode = () => {
    if (!verificationCode) {
      setError({ message: "인증번호를 입력해 주세요" });
      return;
    }

    if (!confirmationResult) {
      setError({ message: "인증번호 전송이 완료되지 않았습니다." });
      return;
    }

    setSmsConfirmed("pending");

    confirmationResult
      .confirm(verificationCode)
      .then((result) => {
        // 인증 성공
        console.log("인증이 완료되었습니다.");
        setSmsConfirmed("success");
        setSmsSendSuccess("");
      })
      .catch((error) => {
        // 인증 실패
        console.log("인증번호가 일치하지 않습니다. 다시 시도해 주세요.");
        setSmsConfirmed("failure");
      });
  };

  const goBack = () => {
    navigate("/signin");
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setError({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHandleSubmited(true);



    // 인증번호 확인중... 이면 폼X
    if (smsConfirmed == "pending") {
      return;
    }

    if (
      !formData.id ||
      !formData.password ||
      !formData.reconfirmpassword ||
      !formData.phonenumber ||
      !formData.nickname
    ) {
      return;
    }

    const res = await fetch(
      "https://us-central1-stories-e89af.cloudfunctions.net/start/api/auth/signup",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    const data = await res.json();
    setError(data);

    if (data.success === false) {
      navigate("/signup");
    } else {
      navigate("/");
      dispatch(signInSuccess(data));
    }
  };

  // 인증번호가 6자리가 되면 자동으로 인증 처리
  useEffect(() => {
    if (verificationCode.length === 6) {
      handleVerifyCode();
    }
  }, [verificationCode]);

  // 숫자만 입력되도록 하고, 최대 6자리로 제한
  const handleCodeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setVerificationCode(value);
    }
    if (value === "") {
      setSmsConfirmed("default"); // 입력값이 비었을 때 기본 상태로 변경
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-nav-container">
        <img onClick={goBack} src="back.svg" className="backicon" />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="signup-box">
          <div className="signup-text">회원가입</div>
          <input
            type="text"
            placeholder="닉네임"
            className={
              handleSubmited &&
              (error.message === "이미 존재하는 닉네임입니다." ||
                !formData.nickname)
                ? "nickname-error"
                : "nickname"
            }
            id="nickname"
            onChange={handleChange}
          />
          {error.message === "이미 존재하는 닉네임입니다." ? (
            <span className="signup-nickname-error-message">
              {error.message}
            </span>
          ) : null}
          {handleSubmited && !formData.nickname ? (
            <span className="signup-nickname-error-message">
              닉네임을 입력해 주세요
            </span>
          ) : null}
          <input
            type="text"
            placeholder="아이디"
            className={
              handleSubmited &&
              (error.message === "이미 존재하는 아이디입니다." || !formData.id)
                ? "signup-id-error"
                : "signup-id"
            }
            id="id"
            onChange={handleChange}
          />
          {error.message === "이미 존재하는 아이디입니다." ? (
            <span className="signup-id-error-message">{error.message}</span>
          ) : null}
          {handleSubmited && !formData.id ? (
            <span className="signup-id-error-message">
              아이디를 입력해 주세요
            </span>
          ) : null}

          <input
            type="password"
            placeholder="비밀번호"
            className={
              handleSubmited &&
              (error.message === "비밀번호가 일치하지 않습니다." ||
                !formData.password)
                ? "signup-password-error"
                : "signup-password"
            }
            id="password"
            onChange={handleChange}
          />
          {handleSubmited && !formData.password ? (
            <span className="signup-password-error-message">
              비밀번호를 입력해 주세요
            </span>
          ) : null}

          <input
            type="password"
            placeholder="비밀번호 재확인"
            className={
              handleSubmited &&
              (error.message === "비밀번호가 일치하지 않습니다." ||
                !formData.reconfirmpassword)
                ? "reconfirm-password-error"
                : "reconfirm-password"
            }
            id="reconfirmpassword"
            onChange={handleChange}
          />
          {error.message === "비밀번호가 일치하지 않습니다." ? (
            <span className="signup-password-error-message">
              {error.message}
            </span>
          ) : null}
          {handleSubmited && !formData.reconfirmpassword ? (
            <span className="signup-password-error-message">
              비밀번호 재확인을 입력해 주세요
            </span>
          ) : null}

          <div className="phone-number-container">
            <input
              type="tel"
              placeholder="휴대폰 번호  (‘-’ 제외)"
              className={
                handleSubmited &&
                (error.message === "이미 존재하는 휴대폰 번호입니다." ||
                  !formData.phonenumber)
                  ? "phone-number-error"
                  : "phone-number"
              }
              id="phonenumber"
              onChange={handleChange}
            />
            {error.message === "이미 존재하는 휴대폰 번호입니다." ? (
              <span className="signup-phonenumber-error-message">
                {error.message}
              </span>
            ) : null}

            {handleSubmited && !formData.phonenumber ? (
              <span className="signup-phonenumber-error-message">
                휴대폰 번호를 입력해 주세요
              </span>
            ) : null}

            {formData.phonenumber ? (
              <img
                src="sendsmsicon.svg"
                onClick={handleSend}
                className="sms-send"
              />
            ) : null}

            {smsSendSuccess == "success" ? (
              <span className="signup-send-success-message">
                인증번호가 전송되었습니다.
              </span>
            ) : smsSendSuccess == "failure" ? (
              <span className="signup-send-error-message">
                인증번호 전송에 실패했습니다. 다시 시도해 주세요.
              </span>
            ) : null}
          </div>

          <input
            type="text"
            placeholder="인증번호 입력"
            className={
              smsConfirmed === "failure" ? "verify-code-failure" : "verify-code"
            }
            value={verificationCode}
            onChange={handleCodeChange}
          />

          {smsConfirmed === "pending" ? (
            <div className="sms-confirmed-pending">인증번호 확인중..</div>
          ) : smsConfirmed === "success" ? (
            <div className="sms-confirmed-success">인증이 완료되었습니다.</div>
          ) : smsConfirmed === "failure" ? (
            <div className="sms-confirmed-failure">
              인증번호가 일치하지 않습니다.
            </div>
          ) : null}
        </div>
        <button type="submit" className="complete-btn">
          완료
        </button>
      </form>
      <div id="recaptcha"></div>
    </div>
  );
}

export default Signup;
