import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChannelService from "./ChannelService"; // ChannelService 파일을 적절히 import

function Home() {
  const [getAllListing, setGetAllListing] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [cartCount, setCartCount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    // ChannelService 스크립트 로드
    ChannelService.loadScript();

    // ChannelService 부트
    ChannelService.boot({
      pluginKey: "618952ab-0bff-462b-8344-5990c5b34786"
    });

    // 컴포넌트가 언마운트될 때 ChannelService 종료
    return () => {
      ChannelService.shutdown();
    };
  }, []); // 빈 배열을 넣어 한 번만 실행되도록 설정

  const getCartCount = async () => {
    if (!currentUser) return; // 사용자 정보가 없는 경우 아무 작업도 하지 않음

    const res = await fetch(
      `https://us-central1-stories-e89af.cloudfunctions.net/start/api/cart/get/${currentUser._id}`,
      {
        credentials: "include",
        mode: "cors", // CORS 모드 설정
      }
    );
    const data = await res.json();
    if (res.ok) {
      const productIds = data.products.map((product) => product.product_id);
      const fetchProductDetails = productIds.map((id) =>
        fetch(
          `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/cart/get/${id}`
        ).then((res) => {
          if (!res.ok) {
            return null; // 응답이 성공적이지 않으면 null 반환
          }
          return res.json();
        })
      );

      const details = await Promise.all(fetchProductDetails);

      // null이 아닌 상품만 필터링
      setCartCount(details.filter((detail) => detail !== null));
    }
  };

  useEffect(() => {
    // 도큐먼트가 있는지 확인하는 API 호출 (예시)
    const getAllListings = async () => {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/all/get`,
        {
          credentials: "include",
          mode: "cors", // CORS 모드 설정
        }
      );
      const data = await res.json();
  
      if (res.ok) {
        // reviewCount 많은 순으로 정렬
        const sortedListings = data.sort((a, b) => b.reviewCount - a.reviewCount);
        setGetAllListing(sortedListings);
        setIsLoading(false);
      }
    };
  
    getAllListings();
  }, []);
  
  

  console.log(getAllListing);

  const handleAddToCartClick = async (e, listing) => {
    e.preventDefault(); // 클릭 이벤트 전파 방지

    if (!currentUser) {
      navigate("/signin");
      return; // 로그인 페이지로 이동 후 함수 종료
    }

    setAddToCartModal(true); // 모달 on

    const cartFormData = {
      user_id: currentUser._id, // 현재 사용자 ID
      products: [
        {
          product_id: listing._id,
        },
      ],
    };

    try {
      const res = await fetch(
        "https://us-central1-stories-e89af.cloudfunctions.net/start/api/cart/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cartFormData),
          credentials: "include",
        }
      );

      const data = await res.json();

      if (res.ok) {
        setTimeout(() => {
          setAddToCartModal(false);
        }, 2000);

        await getCartCount();
      } else {
        setAddToCartModal(data.message)
      }
    } catch (error) {
      setAddToCartModal("장바구니 추가에 실패했습니다.")
    }
  };

  // 카트 링크 클릭 핸들러
  const handleCartClick = (e) => {
    if (!currentUser) {
      e.preventDefault(); // 기본 링크 이동 동작을 막음
      navigate("/signin"); // 로그인 페이지로 이동
    }
  };

  // 프로필 링크 클릭 핸들러
  const handleProfileClick = (e) => {
    if (!currentUser) {
      e.preventDefault(); // 기본 링크 이동 동작을 막음
      navigate("/signin"); // 로그인 페이지로 이동
    }
  };

  return (
    <div className="HomeGrid">
      {addToCartModal ? (
        <div className="add-to-cart-modal">장바구니에 추가되었습니다.</div>
      ) : null}
      <div className="Navbar">
        <div className="a1">
          <img src="stories.svg" className="logo" alt="logo" />
        </div>
        <div className="a2">
          <div className="a2-item">
            <Link to="/cart" className="linktag-cart" onClick={handleCartClick}>
              <img src="cart.svg" className="carticon" alt="cart icon" />
              {cartCount.length > 0 ? (
                <div className="main-cart-count">{cartCount.length}</div>
              ) : null}
            </Link>
            <Link
              to="/profile"
              className="linktag-profile"
              onClick={handleProfileClick}
            >
              <img
                src="profile.svg"
                className="profileicon"
                alt="profile icon"
              />
            </Link>
            <Link to="/search" className="linktag-search">
              <img src="search.svg" className="searchicon" alt="search icon" />
            </Link>
            <Link to="/menu" className="linktag-menu">
              <img src="menu.svg" className="menuicon" alt="menu icon" />
            </Link>
          </div>
        </div>
      </div>

      <div className="main-banner">
        <div>회원가입하고 스토리즈 베타 서비스를 무료로 이용하세요!</div>
        </div>

      <div className="ProductList">
        {isLoading
          ? Array.from({ length: 10 }).map((_, index) => (
              <div className="home-product">
                <div className="b1">
                  <div className="skeleton skeleton-product-thumbnail"> </div>
                </div>
                <div className="b2">
                  <div className="product-container">
                    <div className="skeleton skeleton-product-name"> </div>
                    <div className="skeleton skeleton-who-wrote-this"> </div>
                    <div className="skeleton skeleton-detail-review-rating-star"> </div>
                    <div className="skeleton skeleton-product-price"> </div>
                    <div className="skeleton skeleton-add-to-cart"> </div>
                  </div>
                </div>
              </div>
            ))
          : getAllListing.map((listing, index) => (
              <Link to={`/detail/${listing._id}`} key={index}>
                <div className="home-product">
                  <div className="b1">
                    <img
                      src={listing.imageurl}
                      className="product-thumbnail"
                      alt={listing.productname}
                    />
                  </div>
                  <div className="b2">
                    <div className="product-container">
                      <div className="product-name">{listing.personname}</div>
                      <div className="who-wrote-this">
                        <div>{listing.brandname} 창립자의 이야기</div>
                      </div>
                      <div className="detail-review-rating-star">
                        <div>
                          <img
                            src={
                              listing.rating >= 1
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 0.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                            alt="star rating"
                          />
                          <img
                            src={
                              listing.rating >= 2
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 1.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                            alt="star rating"
                          />
                          <img
                            src={
                              listing.rating >= 3
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 2.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                            alt="star rating"
                          />
                          <img
                            src={
                              listing.rating >= 4
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 3.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                            alt="star rating"
                          />
                          <img
                            src={
                              listing.rating >= 4.8
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 4.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                            alt="star rating"
                          />
                        </div>
                        <div className="rating">{listing.reviewCount}</div>
                      </div>

                      <div className="product-price">{listing.price}원</div>
                      <button
                        className="add-to-cart"
                        onClick={(e) => handleAddToCartClick(e, listing)}
                      >
                        장바구니 담기
                      </button>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
      </div>
      <div className="copyright-stories">Copyright © 2024 Stories Inc.</div>
    </div>
  );
}

export default Home;
