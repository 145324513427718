import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";


function Detail() {
  const navigate = useNavigate();
  const [listingDetail, setListingDetail] = useState(null);
  const { currentUser } = useSelector((state) => state.user);
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const goBack = () => {
    navigate(-1);
  };

  const params = useParams();

  
  useEffect(() => {
    const fetchGetReview = async () => {
      try {
        const res = await fetch(
          `https://us-central1-stories-e89af.cloudfunctions.net/start/api/review/detail/get/${params.listing_id}`,
          {
            credentials: "include",
          }
        );
        const data = await res.json();
  
        // 리뷰 데이터를 최신순으로 정렬
        const sortedReviews = data.reviews.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
  
        setReviewData(sortedReviews);
      } catch (error) {
        console.error("리뷰 데이터를 가져오는 중 오류 발생:", error);
      }
    };
  
    fetchGetReview();
  }, [params.listing_id]);
  

  useEffect(() => {
    const fetchListing = async () => {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/detail/get/${params.listing_id}`,
        {
          credentials: "include",
        }
      );
      const data = await res.json();
      if (data.success === false) {
        return;
      } else {
        setListingDetail(data);
      }
    };
    fetchListing();
  }, [params.listing_id]);

  let formattedDate = "";
  if (listingDetail) {
    const date = new Date(listingDetail.createdAt);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    formattedDate = `${year}.${month}.${day}`;
  }

  const handleAddToCartClick = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      navigate("/signin");
      return;
    }

    setAddToCartModal(true);

    const cartFormData = {
      user_id: currentUser._id,
      products: [
        {
          product_id: params.listing_id,
        },
      ],
    };

    try {
      const res = await fetch("https://us-central1-stories-e89af.cloudfunctions.net/start/api/cart/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cartFormData),
        credentials: "include",
      });

      const data = await res.json();

      if (res.ok) {
        setTimeout(() => {
          setAddToCartModal(false);
        }, 2000);
      } else {
        console.log("장바구니 추가에 실패했습니다.");
      }
    } catch (error) {
      console.log("오류가 발생했습니다.");
    }
  };

  const handleBuyClick = (e) => {
    if (!currentUser) {
      e.preventDefault(); // Prevent the default link behavior
      navigate('/signin'); // Redirect to /signin if not logged in
    }
  };

  return (
    <div>
      {addToCartModal ? (
        <div className="add-to-cart-modal">장바구니에 추가되었습니다.</div>
      ) : null}
      <div className="detail-nav-container">
        <img onClick={goBack} src="/back.svg" className="backicon" />
      </div>
      <div className="detail-grid-container">
        <div className="detail-a">
          <div className="detail-product-name">
            {listingDetail ? listingDetail.personname : null}
          </div>
          <div className="detail-product-price">
            {listingDetail ? listingDetail.price : null}원
          </div>
          <img
            src={listingDetail ? listingDetail.imageurl : null}
            className="detail-thumbnail"
          />
          <Link
            to={`/detailcheckout/${params.listing_id}`}
            className="detail-buynow"
            onClick={handleBuyClick}
          >
            <button className="detail-buynow">바로구매</button>
          </Link>
          <button onClick={handleAddToCartClick} className="detail-addtocart">
            장바구니 담기
          </button>
        </div>
        <div className="detail-b">
          <div className="book-introduce">소개</div>
          <div className="book-introduce-detail">
            {listingDetail ? listingDetail.description : null}
          </div>
        </div>
        <div className="detail-c">
          <div className="detail-imformation">세부 정보</div>
          <div className="detail-c1">
            <span>
              이름 : {listingDetail ? listingDetail.personname : null}
            </span>
          </div>
          <div className="detail-c2">
            <span>출생일 : {listingDetail ? listingDetail.age : null}</span>
          </div>
          <div className="detail-c2">
            <span>
              출생지 : {listingDetail ? listingDetail.birthplace : null}
            </span>
          </div>
          <div className="detail-c3">
            <span>
              업적 : {listingDetail ? listingDetail.achievements : null}
            </span>
          </div>
        </div>

        {/* 리뷰 데이터 반복 렌더링 */}
        <div className="detail-d">
          <div className="detail-d1">
            <div className="detail-d1-1">
              <img className="detail-onestar" src="/onestar.svg" />
              {reviewData.length > 0 &&
              !isNaN(
                reviewData.reduce((total, review) => total + review.rating, 0) /
                  reviewData.length
              ) ? (
                <>
                  <div className="detail-review-score">
                    {(
                      reviewData.reduce(
                        (total, review) => total + review.rating,
                        0
                      ) / reviewData.length
                    ).toFixed(1)}
                  </div>
                  <div className="detail-number-of-reviews">
                    ({reviewData.length})
                  </div>
                </>
              ) : (
                <div className="detail-not-review">리뷰가 존재하지 않습니다.</div>
              )}
            </div>
          </div>
          <div className="detail-d2-container">
            {reviewData.map((review, index) => (
              <div key={index} className="detail-d2">
                <div className="detail-d2-1">
                  <img
                    className="detail-profile-img"
                    src={review.profileimg}
                  />
                  <div>{review.nickname}</div>
                </div>
                <div className="detail-d2-2">
                  <div className="detail-review-rating-star">
                    <img className="c"
                      src={
                        review.rating >= 1
                          ? "/bigstarfilled.svg"
                          : "/bigstarempty2.svg"
                      }
                    />
                    <img
                      src={
                        review.rating >= 2
                          ? "/bigstarfilled.svg"
                          : "/bigstarempty2.svg"
                      }
                    />
                    <img
                      src={
                        review.rating >= 3
                          ? "/bigstarfilled.svg"
                          : "/bigstarempty2.svg"
                      }
                    />
                    <img
                      src={
                        review.rating >= 4
                          ? "/bigstarfilled.svg"
                          : "/bigstarempty2.svg"
                      }
                    />
                    <img
                      src={
                        review.rating >= 5
                          ? "/bigstarfilled.svg"
                          : "/bigstarempty2.svg"
                      }
                    />
                  </div>
                  <div className="detail-review-dot"></div>
                  <div className="detail-review-date">
                    {new Date(review.created_at).toLocaleDateString("ko-KR", {
                      year: "numeric",
                      month: "long",
                    })}
                  </div>
                </div>
                <div className="detail-d2-3">
                  <div>{review.content}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Link to={`/review/${params.listing_id}`}>
        <button className="see-more-reviews-btn">
          후기 더 보기 <img src="/nexticon.svg" />
        </button>
      </Link>
    </div>
  );
}

export default Detail;
